import React from 'react';
import Container from '../components/container';
import SEO from '../components/seo';
import Layout from '../containers/layout';

import {responsiveTitle1} from '../components/typography.module.css';

export default function OrthodoxyPage() {
  return (
    <Layout>
      <SEO title="Orthodoxy" />
      <Container>
        <h1 className={responsiveTitle1}>Orthodoxy</h1>
        <div>
          <h2>Orthodoxy 101</h2>
          <p>
            The Orthodox Church is the One, Holy, Universal, and Apostolic
            Church.
          </p>
          <p>
            Joyfully proclaiming Jesus Christ: the same yesterday, today, and
            forever since 33 A.D.
          </p>
          <p>
            We believe in one God, the Father Almighty, Maker of heaven and
            earth and of all things visible and invisible;
          </p>
          <p>
            And in one Lord, Jesus Christ, the Son of God, the Only-Begotten,
            begotten of the Father before all ages; Light of Light, True God of
            True God, begotten, not made, of one essence with the Father, by
            whom all things were made; Who for us men and for our salvation came
            down from heaven and was incarnate of the Holy Spirit and of the
            Virgin Mary and became man; And was crucified for us under Pontius
            Pilate and suffered and was buried; And resurrected on the third
            day, according to the Scriptures; And ascended into heaven and sits
            at the right hand of the Father; And He will come again in glory to
            judge the living and the dead; Whose kingdom will have no end;
          </p>
          <p>
            And in the Holy Spirit, the Lord, the Giver of Life, who proceeds
            from the Father, who together with the Father and the Son is
            worshipped and glorified, who spake by the prophets.
          </p>
          <p>We believe in one, holy, catholic, and apostolic Church.</p>
          <p>We acknowledge one baptism for the remission of sins.</p>
          <p>
            We await the resurrection of the dead and the life of the world to
            come.
          </p>
          <p>Amen.</p>
          <br />

          <h2>Brief History of the Church</h2>
          <p>
            The Orthodox Church is the original Christian Church, the Church
            founded by the Lord Jesus Christ and described in the pages of the
            New Testament. Her history can be traced in unbroken continuity all
            the way back to Christ and His Twelve Apostles.
          </p>
          <p>
            Incredible as it seems, for over twenty centuries she has continued
            in her undiminished and unaltered faith and practice. Today her
            apostolic doctrine, worship, and structure remain intact. The
            Orthodox Church maintains that the Church is the living Body of
            Jesus Christ.
          </p>
          <p>
            Many of us are surprised to learn that for the first 1000 years of
            Christian history there was just one Church. It was in the eleventh
            century that a disastrous split occurred between Orthodox East and
            Latin West. Although it had been brewing for years, the so-called
            “Great Schism” of 1054 represented a formal—and shocking— separation
            between Rome and Orthodoxy. At the core of the controversy were two
            vitally important areas of disagreement: the role of the papacy, and
            the manner in which doctrine is to be interpreted.
          </p>
          <br />
          <h2>The Real Difference?</h2>
          <p>
            Orthodoxy has maintained the New Testament tradition, whereas Rome
            has often added to it and Protestantism subtracted from it.
          </p>
          <p>
            For example, Rome added to the ancient Creed of the Church, while
            numerous Protestant Churches rarely study or recite it. Rome has
            layers of ecclesiastical authority; much of Protestantism is
            anti-hierarchical or even “independent” in polity. Rome introduced
            indulgences and purgatory; in reaction, Protestantism shies away
            from good works and discipline.
          </p>
          <p>
            In these and other matters, the Orthodox Church has steadfastly
            maintained the Apostolic Faith. She has avoided both the excesses of
            papal rule and of congregational independence. She understands the
            clergy as servants of Christ and His people and not as a special
            privileged class. She preserved the Apostles’ doctrine of the return
            of Christ at the end of the age, of the last judgement and eternal
            life, and continues to encourage her people to grow in Christ
            through union with Him. In a word, Orthodox Christianity has
            maintained the Faith “once for all delivered to the saints.”
          </p>
          <p>
            We believe that our faith, not our obedience or good deeds, is what
            leads us toward salvation. At the same time, however, we also
            believe that obedience and good works are vital aspects of such
            faith. In other words, our works, our actions, our words, and our
            thoughts are all reflections of, and therefore evidence of,
            precisely what we believe or do not believe. We are all sinners, but
            Orthodox believe that we are called toward and capable of becoming
            righteous before God. What makes a person righteous is not privilege
            and not necessarily accomplishments; rather, it is the struggle
            toward holiness in the face of temptation — the choice to pick up
            one’s cross and carry it.
          </p>
          <p>
            In Orthodoxy, there are constant reminders that we are never alone
            in that struggle. God helps us directly through the Divine Mysteries
            – what, in Western terms, we might call a “sacramental” life in the
            the Church – holy baptism, communion, confession, marriage, and so
            forth. We also have the spiritual assistance of Angels and Saints to
            guide and support us in our struggle toward spiritual
            transformation. In much of modern Christianity, turning to the
            heavens for intercessory prayer is a practice that has long been
            abandoned, perhaps even disparaged, but in Orthodoxy we consider it
            one of our greatest treasures. Each of us has a guardian angel to
            protect and help us. Each of us has a patron saint to pray on our
            behalf. Among Serbs, each of our families also has a patron saint to
            pray on our behalf. Indeed, we are not alone.
          </p>
          <p>
            In addition to asking the Saints for their prayers, we also look to
            them as examples of sincere, Orthodox faith. In their earthly lives,
            the various Saints did not necessarily travel similar paths: Some
            were priests and bishops, while others were laity; Some were
            monastics, even hermits, and others were married with children; Some
            were renowned theological teachers or prolific writers, while others
            were completely illiterate; Some were of wealthy and even royal
            upbringing, and others were simple peasants; Some were prophets
            ordained by God, some were called to a life of the strictest
            asceticism from early in their youth, and yet others were great
            sinners who lived much of their lives in depravity; Some received
            crowns of martyrdom through gruesome torments, while others received
            their crowns by martyring their own wills through lifelong ascetic
            labors. What all of the Saints share in common, though, is their
            sincere and fervent faith in Christ. They do help us by praying for
            us, but they also help us by providing us examples to follow, as
            well as the inspiration and courage to seek and preserve the
            profound degree of faith that they maintained in the face of every
            conceivable type of earthly struggle.
          </p>
          <p>
            Above all of the Saints and even the Angels, Orthodox believers
            venerate the Most-Holy Theotokos, or Mother of God. Human like us,
            yet completely pure and undefiled by sin, she is created, not
            divine, yet she is superior to all of creation — truly, more
            honorable than the Cherubim and more glorious than the Seraphim. Her
            holy womb contained that which the heavens are not wide enough to
            contain; therefore, her love as a mother is boundless. Many of the
            major feasts of the Church are dedicated to the Theotokos, and there
            are countless stories of wonderworking Holy Icons bearing her image
            that have worked miracles in the presence of those who believe.
          </p>
          <p>
            Both iconography and icon veneration are central to Orthodox
            worship; our churches and homes are filled with images of the
            Theotokos, of Angels and Saints, and of course of Christ our God,
            and we greet them with a kiss before and after our prayers in much
            the same way that we might greet our loved ones with a kiss as we
            come and go. Contrary to what certain critics believe, we do not
            worship icons. We do not worship anyone or anything other than God,
            the Holy Trinity. However, we respect and reverence icons because we
            consider them windows to heaven. They are not merely pieces of wood
            with paint on them; they depict holiness and Truth. Practically
            every aspect of the painted icon is symbolic and tells a story, a
            piece of Christian theology. More importantly, though, icons help us
            to “see” heaven. They remind us that our prayers are being heard
            because they show us the faces of those who are listening.
          </p>
          <p>
            Like many other aspects of Orthodox faith and practice, the use of
            iconography has its roots not only in Scripture but also in Holy
            Tradition. Many outside of Orthodoxy have attempted to erase even
            the slightest semblances of Tradition from their expressions of
            faith. Within Orthodoxy, however we take all of our spiritual
            directives from Tradition. Our great Serbian Saint Nikolaj
            (Velimirovic) describes Holy Tradition as the vast body of
            “spiritual treasures which we inherited from our holy ancestors,
            which are in absolute accord with the Holy Scriptures, and which
            help us to understand Holy Scripture rightly.” The Scriptures are
            indeed God’s revelation to us, and we are meant to be obedient to
            them, but Orthodoxy emphasizes the importance of understanding that
            revelation in its proper context so as not to misinterpret it. Many
            Christians today discount the authority of anything not explicitly
            stated in Scripture, but they do not realize that Scripture itself
            is but a single piece of Holy Tradition, which is older and much
            farther-reaching than Scripture is. In these very Scriptures
            themselves, the Apostle whom Jesus loved confirms that there is more
            to Christianity than the Bible: “And there were many others things
            which Jesus did, which, if they should be written, every one, I
            suppose that even the world itself could not contain the books that
            should be written” (John 21:25).
          </p>
          <p>
            In much the same way, it is difficult to encapsulate everything that
            Orthodox Christians believe into a single page on a website. The
            preceding words are not meant to introduce any complex matters of
            theology, nor should they be construed as the “most important”
            tenets of Orthodoxy. They are merely a glimpse into a few of the
            aspects of our faith that are outwardly observable and noticeably
            different from Western ideas.
          </p>
        </div>
      </Container>
    </Layout>
  );
}
